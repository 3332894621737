.main-table {
  position: relative;
  width: 100%;
  height: 714px;
  border-radius: 8px ;
  overflow: hidden;
  background-color: #2A2A60;
  box-shadow: 0px 4px 20px 0px rgba(7, 6, 18, 0.3);

  &__filtration {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__spinner {
    position: absolute;
    inset: 0;
    margin: auto;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__table-row {
    display: flex;
    gap: 16px;

    &--body {
      display: flex;
      align-items: center;
      height: 57px;
      padding: 0 24px;
      cursor: pointer;
    }

    &--is-odd {
      background-color: #35356B;
    }

    &--header {
      padding: 0 20px;
      color: #B1B1C6;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.45px;
    }
  }

  &__popup-wrapper {
    border-radius: 16px;
    background: #000;
    box-shadow: rgba(7, 6, 18, 0.3) 0px 4px 20px 0px;
    padding: 16px 24px;
    color: var(--grey-500, #D1D1D1);
    max-width: 358px;
    width: 100%;

    &-mobile {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }
  }

  &__popup-item {
    display: none;
    justify-content: space-between;
  }

  &__popup-title {
    color: #B1B1C6;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 16px;
  }

  &__input {
    padding-left: 24px;
    border: none;
    outline: none;
    color: #B1B1C6;
    background-color: transparent;
  }

  &__search {
    display: flex;
    position: relative;
    align-items: center;
    width: 218px;
    height: 40px;
    padding: 12px 10px;
    border-radius: 8px;
    color: #B1B1C6;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    font-family: IBM Plex Sans;
    letter-spacing: 0.15px;
    background-color: #35356B;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 40px;
    padding: 0;
    border: 2px solid #B1B1C6;
    border-radius: 4px;
    color: #B1B1C6;

    &--filtration {
      width: auto;
      padding: 0 10px;
      font-weight: bold;
      text-transform: capitalize;
    }

    &--active {
      background-color: #B1B1C6;
      color: #35356B;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    color: #B1B1C6;
    gap: 21px;
  }

  &__table-col {
    display: flex;
    align-items: flex-end;
    padding: 10px;
    column-gap: 13px;
    cursor: pointer;

    &.main-table__table-col--body {
      width: 136px;
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--symbol {
      flex-basis: 80px;
    }

    &--symbol.main-table__table-col--body {
      & svg, & img {
        margin-left: 5px;
      }
    }

    &--base-asset {
      flex-basis: 240px;
      flex-wrap: wrap;
    }

    &--quote-asset {
      flex-basis: 142px;
      flex-wrap: wrap;
    }

    &--type {
      flex-basis: 172px;
      text-transform: capitalize;
    }

    &--dissemination {
      flex-basis: 184px;
      justify-content: center;
    }

    &--short-name {
      flex-grow: 1;
      justify-content: center;
    }

    &--inception-date {
      flex-basis: 176px;
    }
  }

  &__sort-icons {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    gap: 2px;
  }

  &__icon {
    &--search {
      position: absolute;
      width: 12px;
      height: 12px;
    }

    &--arrow {
      width: 8px;
    }

    &--sort {
      width: 11px;
    }

    &--is-sorted {
      color: #99D1FF;
    }
  }
}

@media (max-width: 1430px) {
  .main-table {
    &__table {
      &-col {
        &--inception-date {
          display: none;
        }
      }
    }

    &__popup-item {
      &--inception-date {
        display: flex;
      }
    }
  }
}

@media (max-width: 1230px) {
  .main-table {
    &__table {
      &-col {
        &--short-name {
          display: none;
        }
      }
    }

    &__popup-item {
      &--short-name {
        display: flex;
      }
    }
  }
}

@media (max-width: 960px) {
  .main-table {
    &__header {
      flex-direction: column;
      gap: 15px;
    }
  }
}

@media (max-width: 910px) {
  .main-table {
    background-color: #000046;
    height: auto;

    &__popup-wrapper {
      background-color: #4B4B7C;
      max-width: calc(100% - 16px);
      border-radius: 0;
      padding: 4px;

      &-mobile {
        background-color: #181857;
        padding: 10px 14px;
      }
    }

    &__popup-title {
      color: #99D1FF;
    }

    &__table-row {
      &--is-visible {
        background-color: #4B4B7C !important;
      }
    }

    &__table-col {
      &--base-asset, &--quote-asset {
        & span {
          display: none;
        }
      }

      &--symbol {
        flex-basis: 86px;
      }
    }
  }
}

@media (max-width: 810px) {
  .main-table {
    &__table {
      &-col {
        &--short-name {
          display: none;
        }
      }
    }

    &__popup-item {
      &--short-name {
        display: flex;
      }
    }
  }
}

@media (max-width: 700px) {
  .main-table {
    &__header {
      flex-direction: column;
    }

    &__popup-item {
      &--dissemination {
        display: flex;
      }
    }
  }
}

@media (max-width: 523px) {
  .main-table {
    padding: 0 8px;

    &__header {
      flex-direction: column;
      gap: 16px;
    }

    &__table-wrapper, &__table-row--header {
      font-size: 12px;
    }

    &__table-row {
      background-color: transparent;

      &--is-odd {
        background-color: #181857;
      }

      &--is-even {
        background-color: #000046;
      }
    }

    &__search {
      width: 100%;
      background-color: #4B4B7C;
    }

    &__pagination {
      width: 100%;
      justify-content: space-between;
    }

    &__filtration {
      width: 100%;
      flex-direction: column;
    }

    &__btn {
      width: 80px;
      &--filtration {
        width: 120px;
      }
    }

    &__btns {
      justify-content: space-between;
      width: 100%;
    }

    &__table {
      &-col {
        &--quote-asset {
          display: none;
        }
      }
    }

    &__popup-item {
      &--quote-asset {
        display: flex;
      }
    }
  }
}
