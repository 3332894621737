.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background-color: #4B4B7C;
  gap: 20px;

  &__aside-tables {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 20px;
  }

  &__tabs {
    &-list {
      display: flex;
      justify-content: space-around;
    }

    &-item {
      color: #B8CAFB;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.3px;
      padding: 5px 16px;
      outline: none;
      border: none;
      cursor: pointer;
      text-transform: uppercase;

      &.react-tabs__tab--selected {
        border-radius: 5px;
        background: #000046;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
      }
    }

    &-list-wrapper {
      background-color: #154EF3;
      padding: 6.5px 10px;
    }
  }

  &__info-tab-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  &__header {
    padding: 0 24px;
    border-radius: 8px;
    color: #F4FAFF;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    font-family: IBM Plex Sans;
    letter-spacing: 0.32px;
    background-color: #181857;
    box-shadow: 0px 4px 20px 0px rgba(7, 6, 18, 0.30);

    & .slick-slide {
      padding: 10px 0;
    }
  }

  &__tables {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 1145px) {
  .main {
    &__tables {
      flex-direction: column;
    }

    &__aside-tables {
      flex-direction: row;
    }
  }
}

@media (max-width: 910px) {
  .main {
    background-color: transparent;
    padding: 0;

    &__header {
      padding: 10px 24px;
      border-radius: 8px;
      border: 1px solid #4B4B7C;
      box-shadow: 0px 4px 20px 0px rgba(7, 6, 18, 0.30);
      background-color: transparent;
    }
  }
}