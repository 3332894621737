.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__main {
    display: flex;
    flex-grow: 1;
    padding: 24px;
    background-image: url(../../assets/img/bgrnd-main.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__aside {
    z-index: 10;
    position: relative;
    width: 335px;
    min-width: 335px;
    height: 100%;
    background-color: #000046;
    // background-image: url(../../assets/img/side-bgrnd.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-y: bottom;

    &-wrapper {
      display: flex;
      position: sticky;
      top: 0;
      flex-direction: column;
      padding: 24px 16px;
      gap: 18px;
    }
  }
}

@media (max-width: 1000px) {
  .app {
    &__main {
      flex-direction: column;
      background: linear-gradient(80deg, #000046 19.06%, rgba(0, 61, 166, 0.4) 55.52%, #000046 100%), #000046;
    }

    &__aside {
      z-index: 9;
      position: absolute;
      transition: .5s;
      transform: translateX(-335px);
      &--is-open {
        transform: translateX(0);
      }
    }
  }
}

@media (max-width: 910px) {
  .app {
    &__main {
      padding: 0;
    }
  }
}