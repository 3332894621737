.header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  gap: 32px;

  &__logo {
    a {
      display: inline-block;
    }
  }

  &__nav {
    display: flex;
    position: absolute;
    top: 35px;
    right: 0;
    left: 0;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    &-wrapper {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &__menu {
    display: flex;
    gap: 32px;
    
    &-item {
      color: #000046;
      font-weight: 400;
      font-size: 16px;
      line-height: 22.4px;
      text-transform: uppercase;
      
      & a span {
        position: relative;

        &:active {
          color: inherit;
        }

        &:after {
          display: block;
          position: absolute;
          right: 0;
          bottom: -2px;
          left: auto;
          width: 0;
          height: 2px;
          content: '';
          background-color: transparent;
          transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
        }
      }

      & a {
        &:hover span:after { 
          right: auto;
          left: 0;
          width: 100%;
          background-color: #fd6f1d;
        }
      }

      &--active a span {
        font-weight: 600;

        &:after {
          display: block;
          position: absolute;
          right: 0;
          bottom: -2px;
          left: auto;
          width: 100%;
          height: 2px;
          content: '';
          background-color: #fd6f1d;
          transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
        }
      }
    }
  }

  &__btn {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 15px 24px;
    border: 1px solid #cdcddd;
    border-radius: 50px;
    overflow: hidden;
    color: #000046;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    gap: 24px;

    &--main {
      border: 1px solid #FDBC67;
    }

    &:before {
      display: block;
      z-index: -1;
      position: absolute;
      right: 100%;
      width: 175px;
      height: 48px;
      border: 1px solid transparent;
      border-radius: 50px;
      content: '';
      background-color: transparent;
      transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }

    &:hover {     
      &:before {
        right: 0;
        border-color: #cdcddd;
        background-color:#cdcddd;
        transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      }

      & svg {
        color: #000046;
        transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      }
    }

    & svg {
      color: #8F90A6;
      transition: all 0.6s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }

    &-burger {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 24px;
      height: 24px;
      padding: 0;
      gap: 6px;
      display: none;

      span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 24px;
        height: 2px;
        border-radius: 2px;
        background-color:#000046;
        transition: all 0.3s cubic-bezier(0.65, 0.005, 0.35, 0.995);

        &:nth-child(2) {
          top: 9px;
          width: 75%;
        }

        &:last-child {
          top: 19px;
          width: 50%;
        }
      }

      &:hover > span {
        width: 100%;
      }

      &--is-open {
        & span {
  
          max-width: 15px;

          &:first-child {            
            transform: translate(-50%, -50%) rotate(50deg);
            left: 50%;
            top: 50%;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            width: 100%;
            transform: translate(-50%, -50%) rotate(-50deg);
            left: 50%;
            top: 50%;
          }
        }
      }
    }
  }

  &__side-nav {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

@media (max-width: 1145px) {
  .header {
    padding: 8px 16px;
    background-color: #003DA6;

    &__btn-burger {
      display: flex;
      & span {
        background-color: #fff;
      }
    }

    &__side-nav {
      margin-top: 20px;
    }

    &__nav {
      &-wrapper {
        display: none;
        &--is-open {
          display: flex;
          z-index: 99999999;
          padding: 0 16px;
          position: fixed;
          bottom: 0;
          left: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: calc(100% - 63px);
          background-color: white;
          gap: 24px;
        }
      }

      &--is-open {
        position: static;
        align-self: flex-start;
        width: 100%;
        margin: 0;
      }
    }

    &__menu {
      &--is-open {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 0;
      }

      &-item {
        &--is-open {
          width: 100%;
          border-bottom: 1px solid #d8d9e4;
          a {
            display: block;
            width: 100%;
            padding: 1.3rem 0;
          }
        }
      }
    }
  }
}
