/* Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
  margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
  text-decoration: none;
	vertical-align: baseline;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // overflow-y: scroll;
  font-style: normal;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 2px;
    background-color: #41464B;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  object-fit: cover;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}