/* IBM Plex Sans - Regular */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans Regular'), local('IBM Plex Sans Regular'),
      //  url('../fonts/IBMPlexSans/IBMPlexSans-Regular.woff2') format('woff2'),
      //  url('../fonts/IBMPlexSans/IBMPlexSans-Regular.woff') format('woff'),
       url('../fonts/IBMPlexSans/IBMPlexSans-Regular.ttf') format('truetype');
}

/* IBM Plex Sans - Medium */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local('IBM Plex Sans Medium'), local('IBM Plex Sans Medium'),
       url('../fonts/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');
}

/* IBM Plex Sans - SemiBold */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans SemiBold'), local('IBM Plex Sans SemiBold'),
       url('../fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');
}

/* IBM Plex Sans - Bold */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Bold'), local('IBM Plex Sans Bold'),
       url('../fonts/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');
}