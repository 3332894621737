.small-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 514px;
  // min-height: 504px;
  height: 552px;
  padding: 12px 0 0;
  border-radius: 8px;
  background-color: #000046;
  box-shadow: 0px 4px 20px 0px rgba(7, 6, 18, 0.30);
  overflow: hidden;

  &__popup {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 40px;

    &-chart-tooltip {
      border-radius: 20.216px !important;
      width: 154px;
      height: 50px;
      &-content {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        & span {
          color: #99D1FF;
        }
      }

      &-trigger {
        position: absolute;
        right: 0;
      }
    }

    &-wrapper {
      color: #D9D9CD;
      font-family: IBM Plex Sans;
      z-index: 2;
      width: 100%;
      max-width: 1169px;
      height: 634px;
      border-radius: 8px;
      background-color: #000046;
      padding: 20px 33px;
      box-shadow: 0px 4px 20px 0px rgba(7, 6, 18, 0.3);
      &--main {
        max-width: 100%;
        // max-width: 900px;
        // height: 562px;
      }
    }

    &-arrow {
      right: 100%;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-right: 12px solid #000046;
      border-bottom: 12px solid transparent;
    }

    &-chart {
      flex-grow: 1;
    }

    &-header {
      display: flex;
      justify-content: space-between;

      &-content {
        display: flex;
        gap: 35px;
        align-items: center;
      }
    }

    &-title {
      color: #D9D9CD;
      font-family: IBM Plex Sans;
      font-size: 18.531px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-logo {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #D9D9CD;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    &-btn {
      padding: 0;
    }
  }

  &__spinner-wrapper {
    position: absolute;
    inset: 0;
    margin: auto;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;  
  }

  &__no-data {
    position: absolute;
    height: fit-content;
    color: #fff;
    inset: 0;
    margin: auto;
    text-align: center;
  }

  &__tabs {
    position: relative;
    width: 100%;
    flex-grow: 1;

    &-list {
      display: flex;
      padding: 5px;
      border-radius: 8px;
      background-color: #181857;
      gap: 24px;

      &-wrapper {
        padding: 9px 10px;
      }
    }

    & .react-tabs__tab--selected {
      background-color: #000046;
      color: #E4EBFE;
    }

    &-item {
      padding: 6px 12px;
      border-radius: 5px;
      color: #87A4F9;
      cursor: pointer;
      outline: none;
    }
  }

  &__title {
    padding: 8px 24px;
    color: #99D1FF;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 0.48px;
  }

  &__table {
    height: 400px;
    overflow-y: scroll;

    &-wrapper {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &-row {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 40px;
      padding: 0 24px;
      padding-left: 6px;
      gap: 0px;

      &--header {
        color: #D1D1D1;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        font-family: IBM Plex Sans;
        letter-spacing: 0.15px;
      }

      &--body {
        color: #DDE1E8;
        cursor: pointer;

        &:hover {
          background-color: #3d3d64;
        }
      }

      &--is-odd {
        background-color: #181857;
      }

      &--is-popup-open {
        background-color: #4B4B7C;
      }
    }

    &-col {
      font-family: IBM Plex Sans, sans-serif;
      &--ticker {
        flex-grow: 1;
        max-width: 170px;
      }

      &--body {
        display: flex;
        align-items: center;
        gap: 12px;
        &-percent {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: 0.1px;
          gap: 6px;
        }

        & span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &--value {
        width: 52px;
      }

      &--growth {
        width: 80px;
        
        & svg {
          flex-shrink: 0;
        }
      }

      &--adtv {
        width: 87px;
      }

      &--exchange {
        width: 35px;
        &-body {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 1145px) {
  .small-table {
    width: calc((100% - 20px) / 2);
  }
}

@media (max-width: 1870px) {
  .small-table {
    &__popup {
      &-wrapper {
        max-width: 1000px;
        height: 534px;

        &--main {
          max-width: 100%;
        }
      }
      &-chart-tooltip-trigger {
        right: 50%;
      }
    }
  }
}

@media (max-width: 1300px) {
  .small-table {
    &__popup {
      &-chart-tooltip-trigger {
        right: auto;
        left: 37%;
        height: 100%;
      }
      &-arrow {
        display: none;
      }
    }
  }
}

@media (max-width: 910px) {
  .small-table {
    width: 100%;
    &__table {

      &-wrapper {
        padding: 0 6px;
      }

      &-col {
        &--body {
          & span {
            width: auto;
          }
        }

        &--ticker {
          max-width: 200px;
          & img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &-row {
        padding: 0 10px;
      }
    }

    &__tabs-list-wrapper {
      padding: 0 6px;
    }

    &__popup {
      gap: 0;
      &-wrapper {
        padding: 10px 14px;
        height: calc(100vw / 1.8);
      }
    }
  }
}

@media (max-width: 550px) {
  .small-table {
    &__table {
      &-col {
        font-size: 12px;
        &--body {
          & span {
            width: 100px;
          }
        }
      }
    }
    &__popup-title, &__popup-logo, &__popup-btn {
      font-size: 12px;

      & svg {
        height: 15px;
        width: 15px;
      }

      & img {
        height: 15px;
      }
    }
  }
}
