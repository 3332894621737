.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  &__item {
    width: calc((100% - 40px) / 3);
    height: 225px;
    border-radius: 8px;
    background-image: url('../../assets/img/banner-bgrnd.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 4px 20px 0px rgba(7, 6, 18, 0.30);

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &-content {
      display: flex;
      align-items: center;
      color: #CCE8FF;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      gap: 12px;

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: 1870px) {
  .links {
    &__item {
      height: 254px;
    }
  }
}

@media (max-width: 1496px) {
  .links {
    &__item {
      width: calc((100% - 20px) / 2);
      height: 163px;
    }
  }
}

@media (max-width: 1337px) {
  .links {
    &__item {
      width: 100%;
      height: 71.5px;
    }
  }
}

@media (max-width: 910px) {
  .links {
    &__item {
      width: calc((100% - 20px) / 2);
      height: 72px;

      &-link {
        justify-content: flex-start;
        padding: 0 23%;
      }
    }
  }
}

@media (max-width: 520px) {
  .links {
    &__item {
      width: calc(100%);
    }
  }
}